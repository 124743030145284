.container{
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: #fafafb;
}

.container>div{
    max-width: 450px;
    width: 100%;
    margin: auto;
    text-align: right;
}

.container>div>img{
    width: 120px;
}

.paper{
    text-align: left;
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0px 2px 30px #ccc6 !important;
}

.fullWidth{
    width: 100%;
}

.title{
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
}

.detail{
    opacity: 0.5;
}

.btn{
    border-radius: 50px !important;
    padding: 10px !important;
}

.copyright{
    text-align: center;
    opacity: 0.3;
    font-size: 12px;
}

.processing{
    pointer-events: none;
    animation-name: processing;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}
  
@keyframes processing {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}