.root{
    background: #f6f6f6;
    height: calc(100vh);
    display: flex;
    align-items: center;
    padding: 0 17px;
}

.content{
    background: white;
    padding: 40px 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    font-size: 24px;
    text-align: center;
}

.titulo{
    color: #ea2129;
    font-weight: bold;
}

.descripcion{
    color: #818286;
}

.img{
    display: initial;
    width: 100%;
    max-width: 300px;
}