:root{
    --primaryColor: #818286;
    --secondaryColor: #ea2129;
    --subtitleColor: gray;
    --bgSecondaryButton: rgba(0, 0, 0, 0.1);
    --bgHoverSecondaryButton: rgba(0, 0, 0, 0.15);
    --errorColor: #f44336;
    --warningColor: #ff9800;
    --infoColor: #2196f3;
    --successColor: #4caf50;    
    --boxShadow: 0px 0px 10px #ccc6;
    --boxShadowHover: 0px 2px 40px #ccc6;
}