@import '../../../DesignConstants.css';

.btnInputFile{
    float: right;
    margin-top: -35px !important;
    margin-right: 5px !important;
}

.inputLabel>div{
    padding-right: 25px;
}