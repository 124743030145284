.item{
    box-shadow: 0px 0px 10px #ccc6 !important;
    transition: 0.3s !important;
}

.item:hover{
    opacity: 1 !important;
    box-shadow: 0px 2px 40px #ccc6 !important;
}

.item>div{
    padding: 5px 16px !important;
}

.item>div>div{
    display: flex;
    align-items: center;
    text-align: left;
}

.item>div>div>div>.title{
    font-weight: bold;
}

.item>div>div>div>.detail{
    font-size: 12px;
    color: gray;
}

.options{
    text-align: right;
}

.selectClient{
    width: 100%;
    top: 12px;
    position: fixed;
    z-index: 1;
    pointer-events: none;
}

.selectClient>div{
    padding-left: 230px;
    padding-right: 102px;
}

.selectClient>div>div{
    pointer-events: initial;
}

.downloading{
    pointer-events: none;
    animation-name: downloading;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}

@keyframes downloading {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}