.item{
    box-shadow: 0px 0px 10px #ccc6 !important;
    transition: 0.3s !important;
}

.item:hover{
    opacity: 1 !important;
    box-shadow: 0px 2px 40px #ccc6 !important;
}

.item>div{
    padding: 5px 16px !important;
}

.item>div>div{
    display: flex;
    align-items: center;
    text-align: left;
}

.item>div>div>div>.title{
    font-weight: bold;
}

.item>div>div>div>.detail{
    font-size: 12px;
    color: gray;
}

.options{
    text-align: right;
}