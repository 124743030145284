@import '../../../DesignConstants.css';

.header{
    box-shadow: 0px 2px 30px #ccc6;
    background: white;
    padding-top: 10px;
    padding-bottom: 4px;
    position: fixed;
    width: 100%;
    z-index: 1;
}

.logo{
    float: left;
    margin-top: -2px;
}

.logo>img{
    width: 100px;
}

.btnMenu{
    margin-left: 28px !important;
    margin-top: -4px !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.btnProfile{
    float: right;
    margin-top: -4px !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.btnMenu:hover,
.btnProfile:hover{
    background-color: rgba(0, 0, 0, 0.15) !important;
}

.hoverEffect{
    transition: all 0.3s;
    cursor: pointer;
}

.hoverEffect:hover{
    background-color: rgba(0, 0, 0, 0.05);
}

div>a>div>.option{
    padding-left: 15px;
    padding-right: 35px;
}

.profile{
    top: 12px !important;
    min-width: 250px;
}

.profile>div{
    box-shadow: var(--boxShadowHover) !important;
}

.userData{
    padding: 20px 10px 28px 10px;
}

.userName{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: var(--secondaryColor);
}

.userDepartment{
    text-align: center;
}