@import './../../../DesignConstants.css';

.container{
    text-align: center;
    bottom: 100px;
    left: 0;
    width: 100%;
    position: fixed;
}

.icon{
    color: var(--primaryColor);
    margin-bottom: 10px;
}

.title{
    font-weight: bold;
    font-size: 28px;
    padding-bottom: 10px;
}

.description{
    color: var(--subtitleColor);
}