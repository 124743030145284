@import '../../../DesignConstants.css';

.actions{
    padding: 16px 24px !important;
}

.btnHelp{
    float: right;
    margin-top: 6px !important;
    cursor: pointer;
    color: var(--secondaryColor);
    transition: all 0.3s !important;
}

.btnHelp:hover{
    color: var(--primaryColor);
}

.processing{
    pointer-events: none;
    animation-name: processing;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}

@keyframes processing {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}