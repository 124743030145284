@import '../../../DesignConstants.css';

.container{
    padding-top: 30px;
    padding-bottom: 10px !important;
}

.primary{
    font-weight: bold;
    font-size: 16px;
}

.secondary{
    font-size: 11px;
    color: var(--subtitleColor);
}

a~span{
    padding-left: 60px;
}