@import 'DesignConstants.css';

::selection {
  background: rgba(234, 33, 41, 0.1);
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(255,255,255,0);
}

::-webkit-scrollbar-thumb {
  background: rgba(255,255,255,0);
  border-radius: 10px;
  border: solid 2px rgba(255,255,255,0);
}

:hover::-webkit-scrollbar-thumb {
  background: rgba(234, 33, 41, 0.1);
  border: solid 2px rgba(234, 33, 41, 0.1);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--secondaryColor);
  border: solid 2px var(--secondaryColor);
}

.MuiTooltip-tooltip{
  background-color: black !important;
  font-size: 12px !important;
}

.MuiTooltip-arrow{
  color: black !important;
}

a{
  text-decoration: none !important;
  color: inherit;
}

.MuiSkeleton-text{
  transform: unset !important;
}

.MuiFormControl-root,
.MuiTextField-root{
  width: 100%;
}

.MuiFab-root{
  position: fixed !important;
  bottom: 20px;
  right: 20px;
}

input[type='file']{
  display: none;
}

.MuiStepper-root{
  padding: 0 !important;
}

.MuiDrawer-root>.MuiPaper-root{
  width: 100%;
  max-width: 300px;
}

.MuiFormControlLabel-labelPlacementStart{
  margin-left: 0px !important;
  width: 100%;
}

.MuiFormControlLabel-labelPlacementStart>.MuiSwitch-root{
  margin-right: -11px;
}

.MuiFormControlLabel-labelPlacementStart>.MuiTypography-root{
  width: 100%;
}