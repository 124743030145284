.root{
    background: #f6f6f6;
    height: calc(100vh);
    display: flex;
    align-items: center;
    padding: 0 17px;
}

.content{
    text-align: center;
}

.item{
    box-shadow: 0px 0px 10px #ccc6 !important;
    transition: 0.3s !important;
    cursor: pointer;
}

.item:hover{
    opacity: 1 !important;
    box-shadow: 0px 2px 40px #ccc6 !important;
}

.item>div{
    padding: 40px 10px !important;
}