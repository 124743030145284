.item{
    box-shadow: 0px 0px 4px #ccc6 !important;
    transition: 0.3s !important;
}

.item>div{
    padding: 0px !important;
}

.item>div>div{
    display: flex;
    align-items: center;
    text-align: left;
}

.item>div>div>div>.title{
    font-weight: bold;
}

.item>div>div>div>.detail{
    font-size: 3px;
    color: gray;
}