@import './../../../DesignConstants.css';

.downloadFormat{
    float: right;
    margin-top: 5px;
    margin-bottom: 10px;
    color: var(--subtitleColor);
    font-size: 12px;
    transition: 0.2s;
}

.downloadFormat:hover{
    color: var(--secondaryColor);
}

.help{
    padding: 0 10px;
}