@import '../../../DesignConstants.css';

.btnTab{
    border-radius: 5px !important;
    color: rgba(0, 0, 0, 0.54) !important;
    opacity: 1 !important;
    min-width: unset !important;
    padding: 3px !important;
    margin-right: 18px !important;
    background-color: var(--bgSecondaryButton) !important;
    transition: all 0.2s;
}

.btnTitle{
    border-radius: 5px !important;
    padding: 3px !important;
    position: absolute !important;
    background-color: var(--bgSecondaryButton) !important;
}

.btnTab:hover,
.btnTitle:hover{
    background-color: var(--bgHoverSecondaryButton) !important;
}