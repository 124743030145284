@import '../../../DesignConstants.css';

.titulo{
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 20px;
}

.defaultMsg{
    opacity: 0.5;
}

.errorMsg{
    color: var(--errorColor);
}

.warningMsg{
    color: var(--warningColor);
}

.infoMsg{
    color: var(--infoColor);
}

.successMsg{
    color: var(--successColor);
}

.btnVerify{
    border-radius: 50px !important;
    padding: 10px !important;
}

.option{
    padding: 15px 10px;
    cursor: pointer;
    border-radius: 5px;
    background: var(--bgSecondaryButton);
    text-align: center;
    transition: all 0.3s;
}

.option>div{
    padding-top: 5px;
}

.option:hover{
    color: var(--secondaryColor);
}

.fullWidth{
    width: 100%;
}

.verifying{
    pointer-events: none;
    animation-name: verifying;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}
  
@keyframes verifying {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}